import Link from 'next/link'
import { linkMissing } from '@atlasmic/icons/illustrations'
import { Box } from '~components/Box'
import { FocusLayout } from '~components/layouts/FocusLayout'

export const FourZeroFour = () => (
  <FocusLayout>
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box paddingBottom={5}>
        <Box component={linkMissing} />
      </Box>

      <Box maxWidth={100} textAlign='center'>
        <Box
          textStyle='h2'
          color='dark'
        >
          Whoa, this page doesn’t exist.
        </Box>

        <Box
          textStyle='bigger-text'
          color='dark'
          paddingTop={2}
        >
          You can return to the{' '}
          <Link href='/'>
            <a>
              <Box
                display='inline'
                color='primary'
                hoverColor='primary-darken5'
                hoverTextDecoration='underline'
              >
                dashboard
              </Box>
            </a>
          </Link>
          {' '}or{' '}
          <Box
            display='inline'
            color='primary'
            hoverColor='primary-darken5'
            hoverTextDecoration='underline'
            cursor='pointer'
            onClick={() => window.atlasmic({ appType: 'CHAT', action: 'open' })}
          >
            drop us a line
          </Box>
          {' '}
          if you can't find what you're looking for.
        </Box>

      </Box>
    </Box>
  </FocusLayout>
)
